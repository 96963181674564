import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout'
import H1 from '../components/H1'
import H2 from '../components/H2'

const About = () => (
  <Layout>
    <H1>O nas</H1>
    <H2>Gospodarstwo Pasieczne w miejscowości Szynwałd</H2>
    <p>Gospodarstwo Pasieczne w miejscowości Szynwałd, gmina Skrzyszów, powiat Tarnów, 
    województwo małopolskie zostało założone w 1985 roku. 
    Moja przygoda z pszczołami zaczęła się w wieku 9 lat, kiedy dostałem w prezencie pierwszą rodzinę pszczelą od wujka pszczelarza. 
    Początkowo gospodarstwo liczyło kilka rodzin pszczelich ale z biegiem lat pasieka powiększała się 
    i obecnie posiadam 85 rodzin pszczelich w ulach wielkopolskich. 
    Rodziny rozmieszczone są w czterech pasiekach.</p>

    <H2>Pasieka Szynwałd</H2>
    <p>Znajduje się na południowy wschód od Góry św. Marcina, 14 km od Tarnowa, 
      na terenie przysiółka Świniogóra – obecnie Święta Góra. 
      Pasieka ukierunkowana jest na pozyskiwanie miodów odmianowych: mniszkowy, wielokwiatowy, akacjowy, lipowy; pyłku, pierzgi, propolisu i wosku pszczelego. 
      Ukształtowanie terenu, różnorodność występującej roślinności oraz czyste środowisko pozwala na uzyskanie produktów wysokiej jakości.</p>

    <H2>Pasieka Jodłowa</H2>
    <p>Gmina Jodłowa, powiat Dębica, województwo podkarpackie usytuowana jest w lesie z przewagą drzewostanu jodłowego, na pogórzu ciężkowickim. Pasieka znajduje się w Paśmie Brzanki, częściowo objętego ochroną jako park krajobrazowy. Pozostała część Jodłowej (podobnie jak całej gminy) znajduje się na terenie Obszaru Chronionego Krajobrazu Pogórza Ciężkowickiego. Pasieka ukierunkowana jest na pozyskiwanie miodu spadziowego, propolisu i wosku pszczelego. Położenie pasieki z dala od obiektów przemysłowych i szlaków komunikacyjnych sprawia, że pozyskiwane produkty pszczele są najwyższej jakości.</p>

    <H2>Pasieka Biadoliny Szlacheckie</H2>
    <p>Gmina Dębno, powiat Brzesko, województwo małopolskie usytuowana jest obok lasu o różnorodnym zadrzewieniu. To tutaj zaczęła się moja przygoda z pszczołami i tu powstała moja pierwsza pasieka. Otoczenie pasieki to pola uprawne, nieużytki i różne zalesienie pozwala na uzyskiwanie miodów odmianowych: rzepakowy, mniszkowy, wielokwiatowy, akacjowy, lipowy, nawłociowy. W tej pasiece odchowuję młode rodziny pszczele, produkują pakiety pszczele i odkłady pszczele.</p>
    <p>Gospodarstwo pasieczne rozwija się i modernizuje. 
      Wiosną 2010 roku uruchomiłem czwartą pasiekę w malowniczej miejscowości Ruda Kameralna, gmina Zakliczyn, powiat Brzesko, województwo małopolskie, 
      6 km od zalewu Czchowskiego. W 2008 roku wybudowałem nową pracownię pszczelarską z zapleczem i pomieszczeniami socjalnymi. 
      W 2004 roku uzyskałem tytuł Mistrza Pszczelarza. W dalszym ciągu podnoszę swoje kwalifikację poprzez uczestnictwo w szkoleniach 
      i kursach organizowanych przez Polski Związek Pszczelarski. 
      Ukończyłem kurs organizowany przez Państwowy Instytut Weterynaryjny w Puławach – „Podstawowe zasady prowadzenia pasieki, 
      higiena pozyskiwania i przetwarzania produktów pszczelich oraz profilaktyka chorób pszczół w świetle przepisów prawa polskiego i unijnego”. 
      W 2006 roku uzyskałem certyfikat Polskiego Związku Pszczelarskiego – prawo stosowania banderoli PZP. 
      Należę do Koła Pszczelarzy w Szynwałdzie, gdzie jestem Prezesem, ponadto jestem członkiem Zarządu Pogórskiego Związku Pszczelarskiego w Tarnowie. 
      Gospodarstwo pasieczne prowadzę razem z moja żona, której przygoda z pszczołami zaczęła się przed 30-ma laty w mojej pasiece. 
      W naszym gospodarstwie gościmy wycieczki szkolne i osoby interesujące się pszczołami.</p>
  </Layout>
)

export default About
